@tailwind base;
@tailwind components;
@tailwind utilities;

.dark, .dark-theme {
  --blue-1: #0e131d;
  --blue-2: #131a26;
  --blue-3: #19263e;
  --blue-4: #213353;
  --blue-5: #2b4066;
  --blue-6: #394f77;
  --blue-7: #496089;
  --blue-8: #5c749f;
  --blue-9: #394f77;
  --blue-10: #aec8f8;
  --blue-11: #a2bceb;
  --blue-12: #d8eaff;

  --blue-a1: #00000000;
  --blue-a2: #82b6ee0b;
  --blue-a3: #5893fb26;
  --blue-a4: #5e99ff3d;
  --blue-a5: #689efd53;
  --blue-a6: #7aa9fe66;
  --blue-a7: #8ab4ff7a;
  --blue-a8: #96bcff93;
  --blue-a9: #b8d2ff;
  --blue-a10: #b3cdfef8;
  --blue-a11: #b0ccfee9;
  --blue-a12: #d8eaff;

  --blue-contrast: #172135;
  --blue-surface: #18212f80;
  --blue-indicator: #b8d2ff;
  --blue-track: #b8d2ff;
}

@supports (color: color(display-p3 1 1 1)) {
  @media (color-gamut: p3) {
    .dark, .dark-theme {
      --blue-1: oklch(18.7% 0.0216 262);
      --blue-2: oklch(21.6% 0.026 262);
      --blue-3: oklch(27.1% 0.0485 262);
      --blue-4: oklch(32.2% 0.0626 262);
      --blue-5: oklch(37.2% 0.0712 262);
      --blue-6: oklch(42.7% 0.0728 262);
      --blue-7: oklch(48.8% 0.0728 262);
      --blue-8: oklch(55.7% 0.0728 262);
      --blue-9: oklch(86.1% 0.0728 262);
      --blue-10: oklch(83% 0.0728 262);
      --blue-11: oklch(79.3% 0.0728 262);
      --blue-12: oklch(93.4% 0.0473 262);

      --blue-a1: color(display-p3 0 0 0 / 0);
      --blue-a2: color(display-p3 0.5529 0.7647 0.9961 / 0.04);
      --blue-a3: color(display-p3 0.4235 0.6039 1 / 0.141);
      --blue-a4: color(display-p3 0.4314 0.6078 1 / 0.234);
      --blue-a5: color(display-p3 0.4627 0.6275 1 / 0.313);
      --blue-a6: color(display-p3 0.5373 0.6706 1 / 0.388);
      --blue-a7: color(display-p3 0.5882 0.7137 1 / 0.467);
      --blue-a8: color(display-p3 0.6275 0.7451 1 / 0.564);
      --blue-a9: color(display-p3 0.7529 0.8353 1 / 0.983);
      --blue-a10: color(display-p3 0.7333 0.8157 1 / 0.952);
      --blue-a11: color(display-p3 0.7216 0.8118 1 / 0.895);
      --blue-a12: color(display-p3 0.8667 0.9216 1 / 0.992);

      --blue-contrast: #172135;
      --blue-surface: color(display-p3 0.0941 0.1255 0.1804 / 0.5);
      --blue-indicator: oklch(86.1% 0.0728 262);
      --blue-track: oklch(86.1% 0.0728 262);
    }
  }
}

.dark, .dark-theme {
  --gray-1: #131315;
  --gray-2: #191a1c;
  --gray-3: #222326;
  --gray-4: #292a2e;
  --gray-5: #303136;
  --gray-6: #393a40;
  --gray-7: #46484f;
  --gray-8: #5f606a;
  --gray-9: #6c6e79;
  --gray-10: #797b86;
  --gray-11: #b2b3bd;
  --gray-12: #eeeef0;

  --gray-a1: #e3140006;
  --gray-a2: #f8a8080c;
  --gray-a3: #f6cd8616;
  --gray-a4: #fcdeb31d;
  --gray-a5: #ffe8cf24;
  --gray-a6: #fdebe02e;
  --gray-a7: #fcf4f23c;
  --gray-a8: #fcf4ff57;
  --gray-a9: #f5f3ff68;
  --gray-a10: #f4f2fe77;
  --gray-a11: #f5f4ffb5;
  --gray-a12: #fdfdfeef;

  --gray-contrast: #FFFFFF;
  --gray-surface: rgba(0, 0, 0, 0.05);
  --gray-indicator: #6c6e79;
  --gray-track: #6c6e79;
}

@supports (color: color(display-p3 1 1 1)) {
  @media (color-gamut: p3) {
    .dark, .dark-theme {
      --gray-1: oklch(18.7% 0.0042 277.7);
      --gray-2: oklch(21.8% 0.004 277.7);
      --gray-3: oklch(25.6% 0.0055 277.7);
      --gray-4: oklch(28.5% 0.0075 277.7);
      --gray-5: oklch(31.4% 0.0089 277.7);
      --gray-6: oklch(35% 0.01 277.7);
      --gray-7: oklch(40.2% 0.0121 277.7);
      --gray-8: oklch(49.2% 0.0157 277.7);
      --gray-9: oklch(54% 0.0167 277.7);
      --gray-10: oklch(58.6% 0.0165 277.7);
      --gray-11: oklch(77% 0.0138 277.7);
      --gray-12: oklch(94.9% 0.0026 277.7);

      --gray-a1: color(display-p3 0.9843 0.0784 0 / 0.017);
      --gray-a2: color(display-p3 0.9961 0.7294 0.1137 / 0.042);
      --gray-a3: color(display-p3 0.9922 0.8588 0.6 / 0.08);
      --gray-a4: color(display-p3 0.9961 0.9059 0.7608 / 0.109);
      --gray-a5: color(display-p3 1 0.9294 0.851 / 0.138);
      --gray-a6: color(display-p3 1 0.949 0.898 / 0.175);
      --gray-a7: color(display-p3 0.9961 0.9765 0.9608 / 0.23);
      --gray-a8: color(display-p3 0.9843 0.9608 1 / 0.34);
      --gray-a9: color(display-p3 0.9608 0.9569 0.9961 / 0.406);
      --gray-a10: color(display-p3 0.9569 0.9569 1 / 0.463);
      --gray-a11: color(display-p3 0.9686 0.9647 1 / 0.705);
      --gray-a12: color(display-p3 0.9961 0.9961 1 / 0.934);

      --gray-contrast: #FFFFFF;
      --gray-surface: color(display-p3 0 0 0 / 5%);
      --gray-indicator: oklch(54% 0.0167 277.7);
      --gray-track: oklch(54% 0.0167 277.7);
    }
  }
}

.dark, .dark-theme, :is(.dark, .dark-theme) :where(.radix-themes:not(.light, .light-theme)) {
  --color-background: #0e131d;
}

body {
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

.embla {
  overflow: hidden;
}
.embla__container {
  display: flex;
}
.embla__slide {
  flex: 0 0 100%;
  min-width: 0;
}

.grecaptcha-badge {
  visibility: hidden;
}

.bg-stripe-gradient {
  background: repeating-linear-gradient(
    -55deg,
    #1f8269,
    #1f8269 8px,
    #34a387 8px,
    #34a387 13px
  );
}

.non-draggale {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

.spin-wheel-animation {
  transform: rotate(var(--start-rotation-degrees));
}

.spin-wheel-animation.started-spinning {
  animation: spin-wheel var(--start-spinning-time)
      cubic-bezier(0.71, var(--spin-wheel-cb-arg), 0.96, 0.9) 0s 1 normal
      forwards running,
    continueSpin-wheel var(--continue-spinning-time) linear
      var(--start-spinning-time) 1 normal forwards running,
    stopSpin-wheel var(--stop-spinning-time) cubic-bezier(0, 0, 0.35, 1.02)
      var(--stop-spin-time-duration) 1 normal forwards running;
}

@keyframes spin-wheel {
  from {
    transform: rotate(var(--start-rotation-degrees));
  }
  to {
    transform: rotate(var(--start-rotation-degree-complete));
  }
}
@keyframes continueSpin-wheel {
  from {
    transform: rotate(var(--start-rotation-degrees));
  }
  to {
    transform: rotate(var(--start-rotation-degree-complete));
  }
}
@keyframes stopSpin-wheel {
  from {
    transform: rotate(var(--start-rotation-degrees));
  }
  to {
    transform: rotate(var(--final-rotation-degrees));
  }
}
